<template>
	<AppCard class="overflow-visible cursor-pointer" @click="viewResource">
		<div class="resource-card-container relative">
			<img v-if="resourceData.type !== constants.RESOURCE_TYPES.FOLDER" :src="resourceData.banner_image" class="rounded-t-md" />
			<div v-else class=" bg-stan-gray-soft rounded-md h-full grid grid-cols-2 gap-2 p-2 ">
				<div v-for="child in children.slice(0, 4)" class=" rounded-md ">
					<img :src="child.data.banner_image" class="rounded-md" />

				</div>
			</div>
			<div class="details">
				<div class="content">
					<div class="title para-1 text-bold !flex !flex-nowrap items-center justify-between">
						<div class="flex items-center space-x-2">
							<AppIcon v-if="canModifyChannels && !parentId  " src="six-dots" class="color-text-light flex-shrink-0" />
							<span class="line-clamp-3">{{ resourceData.title }}</span>
						</div>
						<div class="flex items-center">
							<div
								v-if="resourceStatus === constants.RESOURCE_STATUSES.INACTIVE"
								class="h-4 p-1.5 rounded bg-community-dark flex items-center justify-center text-white para-3"
							>
								Draft
							</div>
							<AppSelectDropdown
								v-if="canModifyChannels && menuEnabled"
								:menu="true"
								dropdown-menu-class="!min-w-max"
								:options="selectOptions"
								@value-selected="value => valueSelected(value)"
							/>
						</div>
					</div>
					<div class="description text-light para-2 line-clamp-5">{{ resourceData.subHeading }}</div>
				</div>
			</div>
		</div>
		<Teleport to="body">
			<AppModal
				ref="deleteModal"
				title="Just Confirming!"
				subheading="Are you sure you want to delete this resource?"
				action="Delete Resource"
				secondaryAction="Never Mind"
				:loading="modalLoading"
				@cta="deleteResource"
				@secondary-cta="closeDeleteModal"
				@close="closeDeleteModal"
			>
			</AppModal>
		</Teleport>
	</AppCard>
</template>

<script setup>
	import { defineProps, ref, computed, inject } from 'vue'
	import { useRouter } from 'vue-router'
	import { useResourceStore } from '@/stores/resources'
	import constants from '@/global_helper/constants'
	import { useCommunityStore } from '@/stores/communities'
	import axios from 'axios'
	import { configSettings } from '@/plugins/configSettings'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useProductsStore } from '@/stores/products'

	const props = defineProps({
		resource: {
			type: Object,
			required: false,
		},
		menuEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
	})

	const stanAnalytics = inject('stanAnalytics')

	const router = useRouter()

	const resourceStore = computed(() => useResourceStore())
	const communityStore = computed(() => useCommunityStore())
	const communityPublicStore = computed(() => useCommunityPublicStore())
	const productStore = computed(() => useProductsStore())
	const canModifyChannels = computed(() => communityStore.value.canModifyChannels)
	const resourceData = computed(() => props.resource?.data)
	const parentId = computed(() => props.resource?.parent_resource_id)
	const children = computed(()=> {
		if(resourceData.value.type == constants.RESOURCE_TYPES.FOLDER){
			return resourceStore.value.resources.filter(resource => resource.parent_resource_id == props.resource?.resource_id  && resource.status == constants.RESOURCE_STATUSES.GROUPED)
		}
	})
	const resourceStatus = computed(() => props.resource?.status)
	const resourceProducts = computed(()=> productStore.value.resourceProducts)

	const deleteModal = ref(false)
	const modalLoading = ref(false)
	const selectOptions = computed(() => {
    const options = [{ value: 'delete', label: 'Delete', iconBefore: 'trash' }];

		if (parentId.value) {
			options.push({ value: 'ungroup', label: 'Ungroup', iconBefore: 'arrow-left' });
		}

		return options;
	});

	const valueSelected = (value) => {
		switch (value) {
			case 'delete':
				showDeleteModal()
				break
			case 'ungroup':
			if (Array.isArray(resourceData.value.productId) && resourceData.value.productId[0] === null) {
				delete resourceData.value.productId;
			}
			resourceStore.value.updateResource({
				status: constants.RESOURCE_STATUSES.ACTIVE,
				parent_resource_id: -1,
				data: resourceData.value
			}, props.resource?.resource_id)
				break
		}
	};
	
	// Delete modal
	const showDeleteModal = () => {
		if (props.resource?.resource_id) {
			deleteModal.value.show()
		}
	}

	const closeDeleteModal = () => {
		deleteModal.value.hide()
	}

	const deleteResource = async () => {
		modalLoading.value = true
		stanAnalytics('community-resource-delete', {
			props: { resource_id: props.resource?.resource_id }
		})

		if(props.resource?.data?.type == constants.RESOURCE_TYPES.FOLDER){
			for (const child of children.value) {
				if (Array.isArray(child?.data?.productId) && child.data.productId[0] === null) {
					delete child.data.productId;
				}
				resourceStore.value.updateResource({
					status: constants.RESOURCE_STATUSES.ACTIVE,
					parent_resource_id: -1,
					data: child.data
				}, child.resource_id);
        }
		}
		if ([constants.PRODUCT_TYPE.COURSE, constants.PRODUCT_TYPE.DIGITAL_DOWNLOAD].includes(props.resource?.data?.type)) {
			const productId = props.resource?.data?.productId
			const newSelectedProducts = communityStore.value.currentCommunity.data.selectedProducts.filter(product => product !== productId)
			communityStore.value.updateCommunityData({selectedProducts: newSelectedProducts})
		}

		await resourceStore.value.deleteResource(props.resource?.resource_id)
		modalLoading.value = false
	}

	const viewResource = async () => {
			if (props.resource?.resource_id) {
				stanAnalytics('community-resource-view', {
				props: { resource_id: props.resource?.resource_id, menuEnabled: props?.menuEnabled },
				})
				let resourceRoute
				if (props.resource?.data?.type === constants.RESOURCE_TYPES.DIGITAL_DOWNLOAD) {
					resourceRoute = 'resourceDigitalDownload';
				} else if (props.resource?.data?.type === constants.RESOURCE_TYPES.FOLDER) {
					resourceRoute = 'resourceFolder';
				} else if(props.resource?.parent_resource_id){
					resourceRoute = 'child';
				} else {
					resourceRoute = 'resource';
				}
				if(props.resource?.data?.type === constants.PRODUCT_TYPE.COURSE){
					if(communityStore.value.isOwner){
						window.open(`${configSettings.adminDomain}/stores/${communityPublicStore.value.store_id}/page/course/${resourceData.value.productId}`,'_blank')
					}
					else{
						const resource = resourceProducts.value.filter(product => product.page_id === resourceData.value.productId)[0]
						const course_id = resource?.data?.product?.id
						const response = await axios.get(`/v1/communities/${communityStore.value.currentCommunity.slug}/channels/${communityStore.value.currentChannel.name}/resources/course/${course_id}`)
						window.open(`${configSettings.frontDomain}/course/${response.data.course_fan_slug}?token=${response.data.auth_token}`,'_blank')
					}
					
					
				}
				else{
					router.push({
						name: resourceRoute, 
						params: {
							resourceId: props.resource.resource_id,
						},
					})
				}

			}
		}
</script>
<style lang="scss" scoped>
	.card {
		width: 340px;
	}

	img {
		@apply aspect-video object-cover;
	}

	.resource-card-container {
		@apply flex flex-col w-full overflow-y-visible cursor-pointer;
		height: 100%;
	}

	.details {
		@apply p-4 grow flex flex-col gap-5;

		.content {
			@apply gap-2;
			flex: 0 1 auto;
			@apply flex flex-col;

			.title {
				@apply line-clamp-3 overflow-visible;
			}

			.description {
				@apply -tracking-24 text-stan-text-dark line-clamp-5 overflow-hidden;
				flex: 0 1 auto;
			}
		}
	}
</style>
